export const ignoreErrors = [
  /Non-Error promise rejection captured with keys: currentTarget.*/,
  /ResizeObserver loop.*/,
]

const ignoredErrorDescriptions = [
  "undefined is not an object (evaluating 't.replace')",
  't.replace is not a function',
  'a.omappapi.com',
  'window._cio.page',
  '[Cloudflare Turnstile] invalid_rcV',
  '[Cloudflare Turnstile] 600010',
  '[Cloudflare Turnstile] 110600',
  '[Cloudflare Turnstile] 110510',
  '[Cloudflare Turnstile] 300010',
  'captured as promise rejection',
  'Non-Error promise rejection captured with value',
  'Chartboost is not defined',
]

export const beforeSend = (event, hint) => {
  const error = hint?.originalException
  const eventDescription = event.exception?.values?.[0]?.value ?? ''

  // adding HttpClient error message to context
  if (error?.response?.data) {
    event.contexts = {
      ...event.contexts,
      errorResponse: {
        data: error.response.data,
      },
    }
  }

  // The following is a list of errors we are going to ignore / mute and not escalate to Sentry

  // ignore OptinMonster / analyticsjs errors
  if (ignoredErrorDescriptions.some((ignored) => eventDescription.includes(ignored))) {
    return null
  }
  // ignore 'TypeError: Illegal invocation' errors from tracking scripts
  if (
    error?.message?.match(/.*Illegal invocation.*/g) &&
    error?.stack?.match(/.*(Beacon|pixel).*/g)
  ) {
    return null
  }
  // ignore 'TypeError: Failed to fetch' errors from Google scripts
  if (
    error?.message?.match(/.*Failed to fetch.*/g) &&
    error?.stack?.match(/.*viewthroughconversion.*/g)
  ) {
    return null
  }
  // ignore Turnstile errors
  if (
    error?.message?.match(/.*(invalid_rcV|600010|110600|110510|300010|Turnstile).*/g) ||
    error?.stack?.match(/.*(invalid_rcV|600010|110600|110510|300010|Turnstile).*/g)
  ) {
    return null
  }
  // ignore non-Error errors
  if (
    error?.message?.match(/.*(captured as promise rejection).*/g) ||
    error?.stack?.match(/.*(captured as promise rejection).*/g)
  ) {
    return null
  }
  // ignore non-Error MS crawlers errors
  if (
    error?.message?.match(/.*(promise rejection captured with value).*/g) ||
    error?.stack?.match(/.*(promise rejection captured with value).*/g)
  ) {
    return null
  }

  // ignore type errors triggered by GTM
  if (
    error?.message?.includes('vr().getRestrictions is not a function') &&
    error?.stack?.includes('vr().getRestrictions')
  ) {
    return null
  }

  // ignore Instagram browser error
  if (error?.message?.includes("Can't find variable: _AutofillCallbackHandler")) {
    return null
  }

  // ignore errors from 3rd parties
  // https://obe-fitness.sentry.io/issues/4528218631/?project=4504844856066048&query=&referrer=project-issue-stream
  if (
    error?.message?.includes("Can't find variable: usi_js") ||
    error?.message?.includes('usi_js is not defined')
  ) {
    return null
  }

  // Ignore https://obe-fitness.sentry.io/issues/4876328532/ which likely happens during deployments
  // and as recommended here https://github.com/vercel/next.js/issues/43088#issuecomment-1403108553
  if (error?.message?.includes('attempted to hard navigate to the same URL')) {
    return null
  }

  // Ignore ResizeObserver erorrs
  if (
    error?.message?.includes('ResizeObserver loop limit exceeded') ||
    error?.message?.includes('ResizeObserver loop completed with undelivered notifications')
  ) {
    return null
  }

  return event
}
